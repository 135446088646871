export default class AjaxTable {
    /**
     * Helper class to render a table body (optional column headers) after an AJAX call.
     * @param {Object} $el jQuery element
     * @param {Array.<string[]>} data Array of strings within an array of rows. Keypair data should be mapped to an array of strings first!
	 * @param {string[]} headers (Optional) specify the headers for each column. Use if headers are dynamic and not set via view.
     */
	constructor($el, data, headers = []) {
		this.$el = $el;
		this.data = data;
		this.headers = headers;
		this.$thead = this.$el.find('thead');
		this.$tbody = this.$el.find('tbody');
		this.render();
	}

	getRow(arrRow){
		return `
			<tr>
				${arrRow.map(value => {
					return value !== null && typeof value !== 'undefined' && value.length > 0 ? `<td>${value}</td>` : `<td>-</td>`
				}).join('')}
			</tr>
		`
    }

	render() {
		try {
			if (this.headers.length > 0) {
				var headerRow = this.headers.map(item => `<th>${item}</th>`).join('');
				this.$thead.html(headerRow);
			}
			var rows = this.data.map(item => this.getRow(item)).join('');
			this.$tbody.html(rows);
		} catch (error) {
			console.error('There was an issue generating an Ajax Table', error);
		}
	}
}

