import AjaxTable from '../helpers/ajaxTable';
import { displayErrorMessage } from '../helpers/displayError';
import { isNullUndefinedEmpty } from '../helpers/helpers';
import userStateManager from '../helpers/stateManager';

export const component = async () => {
    const momentModule = await import('moment-mini');
    window.moment = momentModule.default;

    const $serviceHistoryTableElement = $('#my-service-history-table');

    userStateManager.getUserProfileData(function (data) {
        if (!isNullUndefinedEmpty(data.ProfileInfo)) {
            if (data && data.apiProblemOccured) {
                displayErrorMessage($serviceHistoryTableElement.parent());
                return;
            }

            if (!isNullUndefinedEmpty(data.ProfileInfo.ServiceHistory)) {
                const fullListing = $serviceHistoryTableElement.data('full');
                const max = 5;

                //Format data ready to map to table
                var serviceHistoryData = data.ProfileInfo.ServiceHistory.map((item) => {
                    const startDateFormatted = !isNullUndefinedEmpty(item.StartDate) ? moment(item.StartDate).format('DD/MM/YYYY') : '-';
                    const endDateFormatted = !isNullUndefinedEmpty(item.EndDate) ? moment(item.EndDate).format('DD/MM/YYYY') : '-';

                    return [item.BGUName, item.BguType, item.Position, startDateFormatted, endDateFormatted];
                });

                if (fullListing) {
                    new AjaxTable($serviceHistoryTableElement, serviceHistoryData);
                } else {
                    new AjaxTable($serviceHistoryTableElement, serviceHistoryData.slice(0, max));

                    //show a button if more than max
                    if (serviceHistoryData.length > max) {
                        $('.button-wrapper.my-service-history-button-wrapper').css({
                            display: 'block',
                        });
                    }
                }
            }
        }
    });
};
